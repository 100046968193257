<template>
  <div class="row">
    <input-select
      v-model="filtro.tipoCadastro"
      :options="opcoes.tipoCadastro"
      class="col-12"
      :label="$t('modulos.solicitacao_cadastro.formulario.tipo_cadastro')"
      :loading="loadingTipoCadastro"
      em-filtro
    />
    <input-select
      v-model="filtro.status"
      :options="opcoes.status"
      class="col-12 col-md-6"
      :label="$t('modulos.solicitacao_cadastro.formulario.situacao')"
      :loading="loadingStatus"
      em-filtro
    />
    <input-date
      v-model="dataSolicitacao"
      class="col-12 col-md-6"
      :label="$t('modulos.solicitacao_cadastro.formulario.data_solicitacao')"
      :placeholder="$t('geral.inputs.selecione')"
      em-filtro
    />
  </div>
</template>
<script>
import EnumeradorService from '@common/services/cadastros/EnumeradorService';
export default {
  props: {
    filtro: {},
  },
  data() {
    return {
      dataSolicitacao: null,
      loadingStatus: false,
      loadingTipoCadastro: false,
      opcoes: {
        status: [],
        tipoCadastro: [],
      },
    };
  },
  watch:{
  dataSolicitacao: function () {
      this.filtro.dataSolicitacao = this.dataSolicitacao?.substr(0, 10);
    },
  },
  mounted() {
    this.buscarTipoCadastro();
    this.buscarStatus();
  },
  methods: {
    buscarStatus: function () {
      this.loadingStatus = true;
      EnumeradorService.buscar('EnumStatus')
      .then((res) => {
        this.opcoes.status = res;
      })
      .finally(() => {
          this.loadingStatus = false;
      });
    },
    buscarTipoCadastro: function () {
      this.loadingTipoCadastro = true;
      EnumeradorService.buscar('EnumTipoCadastro')
      .then((res) => {
        this.opcoes.tipoCadastro = res;
      })
      .finally(() => {
          this.loadingTipoCadastro = false;
      });
    },
  }
};
</script>

