import _ from 'lodash'
export class SolicitacaoCadastroModel {
  constructor(
    { id,
      tipoCadastro,
      texto,
      observacao,
      usuarioSolicitanteId,
      status,
      dataSolicitacao= new Date(),
      descricaoResolucao,
      usuarioSolicitante,
      usuarioResolucao,
      dataResolucao,
    }) {
    this.id = id;
    this.tipoCadastro = tipoCadastro;
    this.texto = texto;
    this.status= status;
    this.observacao = observacao;
    this.usuarioSolicitanteId = usuarioSolicitanteId;
    this.usuarioSolicitante = usuarioSolicitante;
    this.dataSolicitacao = dataSolicitacao;
    this.usuarioResolucao = usuarioResolucao;
    this.descricaoResolucao = descricaoResolucao;
    this.dataResolucao = dataResolucao;

  }
    get request() {
      let retorno = _.cloneDeep(this);
      if(!this.id) retorno.usuarioSolicitanteId = this.usuarioSolicitante?.value;
      if(this.id) {
        retorno.usuarioSolicitanteId = this.usuarioSolicitante?.value
        retorno.usuarioResolucaoId = this.usuarioResolucao?.value
        if(this.tipoCadastro?.value) retorno.tipoCadastro = this.tipoCadastro?.value
      }
    return retorno;
  }
}
