import { BasicoApi } from '@common/api';
const SolicitacaoCadastroService = {
  listar: function (parametros) {
    return BasicoApi.get()('/solicitacaocadastro', parametros);
  },
  buscar: function (id) {
    return BasicoApi.get()(`/solicitacaocadastro/${id}`);
  },
  salvar: function (form) {
    if (form.id) return BasicoApi.put()(`/solicitacaocadastro/${form.id}`, form.request);
    return BasicoApi.post()('/solicitacaocadastro', form.request);
  },
  excluir: function (id) {
    return BasicoApi.delete()(`/solicitacaocadastro/${id}`);
  },
};

export default SolicitacaoCadastroService;
